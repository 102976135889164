import React, {useContext, useEffect, useState, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {PlayerContext} from '../contexts/PlayerContext';

const Player = (props) => {

    const {player, clearPlayer, updatePlayerItem} = useContext(PlayerContext);

    const [isPlaying, setIsPlaying] = useState(false);
    const [nowPlaying, setNowPlaying] = useState(player.file);
    const [percentComplete, setPercentComplete] = useState(0);
    const [showSpeedModal, setShowSpeedModal] = useState(false);

    const audioRef = useRef();

    const closePlayer = () => {
        clearPlayer();
        props.closePlayer();
    }

    const openPlayer = () => {
        props.openPlayer();
        audioRef.current.src = player.file;
    }

    const play = () => {
        setIsPlaying(true);
        const audioPromise = audioRef.current.play()
        audioPromise.then(()=>{
            audioRef.current.currentTime=player.position;
        }).catch((err) => {
            console.log(err);
        })
    }
    const pause = () => {
        setIsPlaying(false);
        audioRef.current.pause();
    }

    const jumpSeconds = (secs) => {
        let later = audioRef.current.currentTime+secs;
        audioRef.current.currentTime = later;
    }

    const showInMinutes = (secs) => {
        const hours = Math.floor(secs / 60 / 60);
        let minutes = Math.floor(secs / 60) - (hours * 60);
        let seconds = secs % 60;
        if(minutes<10){
            minutes = '0'+minutes;
        }
        if(seconds<10){
            seconds = '0'+seconds;
        }
        if(hours>0){
            return hours+':'+minutes+':'+seconds;
        }else{
            return minutes+':'+seconds;
        }
    }

    useEffect(()=>{
        if(player.id>0 && !props.isOpen){
            openPlayer();
        }
        if(player.file!==nowPlaying){
            audioRef.current.src = player.file;
            setNowPlaying(player.file);
            setIsPlaying(true);
            const audioPromise = audioRef.current.play();
            audioPromise.then(()=>{
                if(player.duration===1 && audioRef.current.duration!=='NaN'){
                    updatePlayerItem('duration', parseInt(audioRef.current.duration));
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        if(player.duration>1){
            let pc = (player.position/player.duration)*100;
            setPercentComplete(pc);
        }
        if(audioRef.current.src!==player.file){
            audioRef.current.src = player.file;
            audioRef.current.currentTime = player.position;
        }
        localStorage.setItem('player', JSON.stringify(player));
        //console.log('running localstorage set');
        // eslint-disable-next-line
    }, [player]);

    useEffect(()=>{
        if(isPlaying){
            let myInterval = setInterval(()=>{
                updatePlayerItem('position', parseInt(audioRef.current.currentTime));
                //console.log('running duration interval');
            }, 1000);
            return ()=>{
                clearInterval(myInterval);
            }
        }
        // eslint-disable-next-line
    }, [isPlaying]);

    const seekToPosition = (e) => {
    //console.log(e.nativeEvent.offsetX+' out of '+e.currentTarget.offsetWidth);
        let newPercent = e.nativeEvent.offsetX/e.currentTarget.offsetWidth;
        let newPosition = parseInt(player.duration*newPercent);
        audioRef.current.currentTime = newPosition;
    }

    const changeSpeedTo = (val) => {
        audioRef.current.playbackRate = val;
        updatePlayerItem('speed', val);
        setShowSpeedModal(false);
    }

    return (
        <>
        <audio ref={audioRef}  />
        <div className="player-speed-modal" style={{bottom: showSpeedModal ? '100%' : '-120%'}}>
                Speed: 
                <button className={`player-speed-modal-option${player.speed===1.0 ? (' active') : ('')}`} onClick={()=>changeSpeedTo(1.0)}>1.0x</button>
                <button className={`player-speed-modal-option${player.speed===1.2 ? (' active') : ('')}`} onClick={()=>changeSpeedTo(1.2)}>1.2x</button>
                <button className={`player-speed-modal-option${player.speed===1.5 ? (' active') : ('')}`} onClick={()=>changeSpeedTo(1.5)}>1.5x</button>
            </div>
        <div className="player-bottom-bar" style={{position: 'relative'}}>

            

            <div style={{width: '100%', height: 24, backgroundColor: '#333', position: 'relative'}} onClick={(e)=>seekToPosition(e)}>
                <div style={{position: 'absolute', top: 0, left: 5, height: 22, overflow: 'hidden', zIndex:3}}>{player.title}</div>
                <div style={{position: 'absolute', top: 1, left: 1, height: 22, width: percentComplete+'%', backgroundColor: '#777'}}></div>
            </div>

            <div className="player-image-holder" style={{float: 'left'}}>
                <NavLink to={`/podcast/${player.id}`}><img src={player.image} alt={player.title} style={{maxWidth: 50}} /></NavLink>
            </div>
            <div className="player-controls" style={{display: 'inline-block'}}>

                {isPlaying && (
                    <div className="player-seek-backward" style={{display: 'inline-block'}}>
                        <button className="btn btn-sm link" style={{marginRight: 2, color: '#FFF'}} onClick={()=>jumpSeconds(-25)}>-25</button>
                    </div>
                )}
                

                <div className="player-main-button" style={{display: 'inline-block', margin: '2px 5px'}}>
                    {isPlaying ? (
                        <button className="player-button" onClick={()=>pause()}>
                            <i className="fa fa-pause"></i>
                        </button>
                    ):(
                        <button className="player-button" onClick={()=>play()}>
                            <i className="fa fa-play"></i>
                        </button>
                    )}
                </div>

                {isPlaying && (
                <div className="player-seek-forward" style={{display: 'inline-block'}}>
                    <button className="btn btn-sm link" style={{marginLeft: 2, color: '#FFF'}} onClick={()=>jumpSeconds(25)}>+25</button>
                </div>
                )}

                {isPlaying && (
                    <div className="player-speed-setting" style={{display: 'inline-block'}}>
                        <button className="btn btn-sm link" style={{color: '#FFF', lineHeight: '1.1'}} onClick={()=>setShowSpeedModal(true)}>Speed<br />{player.speed}x</button>
                    </div>
                )}
                

            </div>

            

            

            <div className="player-time-display" style={{float: 'right'}}>
                
                <div style={{fontSize: 10, color: '#EFEFEF', textAlign: 'center', marginTop: 5, lineHeight: 1.2, minWidth: 50}}>
                    {showInMinutes(player.position)}
                    {player.duration>1 && (
                        <>
                        <br />
                        out of 
                        <br />
                        {showInMinutes(player.duration)}
                        </>
                    )}

                </div>
            </div>

            <div className="player-close-button" style={{position: 'absolute', top: '-30px', right: 0, display: props.isOpen ? 'inline-block' : 'none'}}>
                <button className="btn btn-sm link" style={{ border: 'none', backgroundColor: '#000', borderRadius: 0}} onClick={()=>closePlayer()}>
                    <i className="fa fa-times" style={{color: '#FFF'}}></i>
                </button>
            </div>
        </div>
        
        </>
    )
}
export default Player;