import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import PersonEpisodesList from './PersonEpisodesList';
import SideModalSideContent from './SideModalSideContent';

const PersonDetail = (props) => {

    const [personId] = useState(props.id);

    const [loaded, setLoaded] = useState(false);
    const [details, setDetails] = useState({});
    //const [feedType, setFeedType] = useState('podcast');

    const fetchDetails = async () => {
        setLoaded(false);
        const res = await fetch('https://untethered.io/api/people/detail/?id='+personId);
        const data = await res.json();
        setDetails(data);
        setLoaded(true);
    }

    useEffect(()=>{
        fetchDetails();
        // eslint-disable-next-line
    }, [personId]);

    const onImageError = async (e) => {
        console.log('we do not have this picture');
        e.target.src = 'https://untethered.io/images/no_image_found.png';
        //ev.target.src = 'https://www.freefunder.com/v2/images/no-photo.png';
        let img = e.target;
        try {
            const fetchResponse = await fetch(`https://untethered.io/api/people/updatePerson/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: personId,
                    field: 'picture',
                    value: ''
                })
            });
            const data = await fetchResponse.json();

            if(data.status==='success'){
                if(data.updated_img){
                    img.src = data.new_img;
                    setDetails({...details, picture: data.new_img});
                }
            }

            return data;

        } catch (e) {
            return e;
        }    
    }

    if(!loaded){
        return (
            <div style={{padding: 10, minHeight: 300, backgroundColor: '#FFF'}}>
                <i className="fa fa-spinner fa-spin"></i> Loading Person Details...
            </div>
        )
    }
    return(
        <div style={{padding: 10, backgroundColor: '#FFF'}}>
            <SideModalSideContent />
            <div className="sidemodal-main-content">

                {details.picture.length>0 && (
                    <img src={details.picture} onError={(e)=>onImageError(e)} style={{maxWidth: 100, margin: '0 10px 10px 0'}} alt={details.name} align="left" />
                )}
                <h3>{details.name}</h3>
                <div style={{margin: '10px 0'}}>
                    {details.brands.length>0 && (
                        <>
                        Host of <NavLink to={`/brand/${details.brands[0].id}`}>{details.brands[0].name}</NavLink>
                        {details.brands.length>1 && (
                            <>
                            <span> and <NavLink to={`/person/${details.brands[1].id}`}>{details.brands[1].name}</NavLink></span>
                            {details.brands.length>2 && (
                                <span> and <NavLink to={`/person/${details.brands[2].id}`}>{details.brands[2].name}</NavLink></span>
                            )}
                            </>
                        )}
                        </>
                    )}
                    
                </div>
                <div style={{margin: '10px 0'}}>
                    <a className="btn btn-primary btn-sm" style={{backgroundColor: '#55acee', color: '#fff', border: 'none'}} href={`https://twitter.com/${details.twitter}`} target="_blank" rel="noreferrer noopener">
                        <i className="fa fa-twitter"></i> @{details.twitter}
                    </a>
                </div>
                <div>{details.about}</div>

                <h4 style={{margin: '10px 0 0'}}>Guest Posts</h4>
                <div className="person-guest-episodes-list">
                    <PersonEpisodesList personId={personId} />
                </div>
                
            </div>
            <div style={{clear: 'both'}}></div>
        </div>
    )
}

export default PersonDetail;