import React from 'react';
import AccountMenu from '../components/AccountMenu';

const Header = (props) => {
    return (
        <div className="header">
            <div className="header-left d-inline-block d-md-none">
            <i className="fa fa-sliders" onClick={()=>props.toggleFilters()}></i>
            </div>
            <div className="header-left d-none d-md-inline-block">
            untethered
            </div>
            <div className="header-center d-inline-block d-md-none">untethered</div>
            <div className="header-right">
                <AccountMenu />
            </div>
        </div>
    )
}
export default Header;