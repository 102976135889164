import React, { useState, useEffect } from 'react';

//import {FilterContext} from '../contexts/FilterContext';
import FeedItemPodcast from './FeedItemPodcast';

const PersonEpisodesList = (props) => {
    //const {filters} = useContext(FilterContext);
    const [loaded, setLoaded] = useState(false);
    const [results, setResults] = useState([]);
    const [resultSet, setResultSet] = useState(1);
    const [searchFilters, setSearchFilters] = useState({
        orderBy: 'magic_sort',
        keyword: ''
    })
    const [keyword, setKeyword] = useState('');
    const [paging, setPaging] = useState({
        thisPage: 1,
        pageCount: 1,
        resultCount: 1
    })

    const fetchPosts = async (unload) => {
        
        if(unload){
            setLoaded(false);
            setResults([]);
            setResultSet(1);
        }
        let startPoint = unload ? 0 : (resultSet-1)*18;
        let newResults = unload ? [] : results;
        const res = await fetch('https://untethered.io/api/episodes/list/?cat=all&descriptor=all&orderby='+searchFilters.orderBy+'&search='+searchFilters.keyword+'&start='+startPoint+'&person_id='+props.personId);
        const data = await res.json();

        setPaging({
            thisPage: data.this_page,
            pageCount: data.page_count,
            resultCount: data.results_count
        })

        for(let i=0; i<data.results.length; i++){
            newResults.push(data.results[i]);
        }
        setResults(newResults);
        setResultSet(unload ? 2 : resultSet+1);
        setLoaded(true);
        //console.log(data);
    }

    useEffect(()=>{
        fetchPosts(true);
        // eslint-disable-next-line
    }, [searchFilters]);

    const doKeywordSearch = () => {
        setSearchFilters({
            ...searchFilters, keyword: keyword
        })
    }
    const clearKeywordSearch = () => {
        setKeyword('');
        setSearchFilters({
            ...searchFilters, keyword: ''
        })
    }

    if(!loaded){
        return (
            <div>Checking...</div>
        )
    }
    let formatter = Intl.NumberFormat();
    return (
        <div style={{margin: '0 -20px'}}>
            <div style={{textAlign: 'center', padding: '15px 0'}}>
                <div className="row" style={{margin: '0 10px'}}>
                    <div className="col-12 col-md-6" style={{marginTop: 5}}>
                        <select className="form-control" onChange={(e)=>setSearchFilters({...searchFilters, orderBy: e.target.value})} value={searchFilters.orderBy}>
                            <option value="magic_sort">Magic Sort</option>
                            <option value="published_date">Most Recent</option>
                            <option value="favorites">Most Favorites</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-6" style={{marginTop: 5}}>
                        <div className="input-group">
                            <input type="text" className="form-control" name="keyword" onChange={(e)=>setKeyword(e.target.value)} placeholder="Any" id="searchTerm" value={keyword} />
                            <span className="input-group-btn">
                                { (searchFilters.keyword!=='' && searchFilters.keyword===keyword) ? (
                                    <button onClick={()=>clearKeywordSearch()} className="btn btn-link">Clear</button>
                                ):(
                                    <button onClick={()=>doKeywordSearch()} className="btn btn-secondary">Search</button>
                                ) }
                            </span>
                        </div>
                    </div>
                </div>

            </div>
                {results.map((item, i)=>{
                    return (
                        <div key={i}>
                            <FeedItemPodcast item={item} />
                        </div>
                    )
                })}
                <div style={{textAlign: 'center', padding: '10px 0 15px'}}>
                    {results.length>0 ? (
                        <>
                            Loaded {formatter.format(results.length)} of {formatter.format(paging.resultCount)} results<br />
                            {paging.pageCount>paging.thisPage && (
                                <button className="btn btn-outline-secondary" onClick={()=>fetchPosts(false)} style={{marginTop: 5}}>Load More Results</button>
                                
                            )}
                        </>
                    ):(
                        <>No Results</>
                    )}
                </div>
            
        </div>
    )
}

export default PersonEpisodesList;