import React, {createContext, useState} from 'react';

export const UserContext = createContext();

const UserContextProvider = (props) => {

    const initialState = JSON.parse(localStorage.getItem('user')) || {
        id: 0,
        first: '',
        last: '',
        email: '',
        token: ''
    }

    const [user, setUser] = useState(initialState);

    const logout = () => {
        setUser({
            id: 0,
            first: '',
            last: '',
            email: '',
            token: ''
        });
    }
    const updateUser = (newUser) => {
        setUser(newUser);
        localStorage.setItem('user', JSON.stringify(newUser));
    }
    return (
        <UserContext.Provider value={{user, logout, updateUser}}>
            {props.children}
        </UserContext.Provider>
    )
}
export default UserContextProvider;