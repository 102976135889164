import React, {createContext, useState} from 'react';

export const PlayerContext = createContext();

const PlayerContextProvider = (props) => {

    const initialState = JSON.parse(localStorage.getItem('player')) || {
        id: 0,
        file: '',
        image: '',
        title: '',
        playing: false,
        position: 0,
        duration: 1,
        speed: 1
    }

    const [player, setPlayer] = useState(initialState);

    const clearPlayer = () => {
        setPlayer({
            id: 0,
            file: '',
            image: '',
            title: '',
            playing: false,
            position: 0,
            speed: 1,
            duration: 1,
        });
    }
    const updatePlayerItem = (property, value) => {
        setPlayer(prev=>({...prev, [property]: value}));
    }

    const addToQueue = (id, file, image, title) => {
        console.log('add '+title+' to queue');
    }
    
    //console.log(player);
    return (
        <PlayerContext.Provider value={{player, clearPlayer, updatePlayerItem, addToQueue}}>
            {props.children}
        </PlayerContext.Provider>
    )
}
export default PlayerContextProvider;