import React from 'react';
import {NavLink} from 'react-router-dom';
import PodcastDetail from './PodcastDetail';
import BrandDetail from './BrandDetail';
import PersonDetail from './PersonDetail';

const SideModal = ({type, id}) => {

    
        return (
            <div className="sidemodal-inside-wrapper">
                <div className="sidemodal-close-button">
                    <NavLink to="/" className="btn btn-link btn-sm" style={{backgroundColor: '#fff', borderRadius: 0, border: 'none'}}><i className="fa fa-times"></i></NavLink>
                </div>
                {type==='podcast' && (
                    <PodcastDetail id={id} />
                )}
                {type==='brand' && (
                    <BrandDetail id={id} />
                )}
                {type==='person' && (
                    <PersonDetail id={id} />
                )}
            </div>
        );
}

export default SideModal;