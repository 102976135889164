import React, {createContext, useState} from 'react';

export const FilterContext = createContext();

const FilterContextProvider = (props) => {
    const [filters, setFilters] = useState({
        contentType: 'podcast',
        subFilter: false,
        keyword: '',
        category: 'all',
        descriptor: 'all',
        orderBy: 'magic_sort'
    });

    const resetFilters = () => {
        setFilters({
            contentType: 'podcast',
            subFilter: false,
            keyword: '',
            category: 'all',
            descriptor: 'all',
            orderBy: 'magic_sort'
        });
    }
    const updateFilters = (newFilters) => {
        setFilters(newFilters);
    }
    
    //console.log(filters);
    return (
        <FilterContext.Provider value={{filters, updateFilters, resetFilters}}>
            {props.children}
        </FilterContext.Provider>
    )
}
export default FilterContextProvider;