import React, { useState, useEffect, useContext } from 'react';
import {UserContext} from '../../contexts/UserContext';

const LoginForm = () => {

    const {user, updateUser} = useContext(UserContext);

    const [checkingToken, setCheckingToken] = useState(false);
    const [checkingCredentials, setCheckingCredentials] = useState(false);
    const [credentials, setCredentials] = useState({email: '', password: ''});
    const [errorMessage, setErrorMessage] = useState('');

    const onChangeInput = (field, value) => {
        setCredentials({...credentials, [field]: value});
    }

    const checkCredentials = async (e) => {
        e.preventDefault();
        setCheckingCredentials(true);
        setErrorMessage('');
        const res = await fetch('https://untethered.io/api/auth/checkCredentials/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: credentials.email,
                password: credentials.password
            })
        });
        const data = await res.json();
        if(data.status==='success'){
            updateUser(data.user);
        }else{
            setErrorMessage(data.message);
            setCheckingCredentials(false);
        }
    }

    const checkToken = async () => {
        
            if(!checkingToken){
                setCheckingToken(true);
                const res = await fetch('https://untethered.io/api/auth/checkToken/', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: user.token
                    })
                });
                const data = await res.json();
                setCheckingToken(false);
                if(data.status==='success'){
                    updateUser(data.user);
                }
            }
        
    }

    useEffect(()=>{
        if(user.token && user.token!==''){
            checkToken();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <form onSubmit={(e)=>checkCredentials(e)}>
            <table className="table table-sm table-borderless">
                <tbody>
                    {errorMessage!=='' && (
                        <tr>
                            <td>
                                <div className="alert alert-danger">
                                    {errorMessage}
                                </div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Email</td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" className="form-control" value={credentials.email} onChange={(e)=>onChangeInput('email', e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Password</td>
                    </tr>
                    <tr>
                        <td>
                            <input type="password" className="form-control" value={credentials.password} onChange={(e)=>onChangeInput('password', e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="submit" className="btn btn-block btn-secondary" disabled={checkingCredentials ? true : null}>
                                {checkingCredentials ? (
                                    <>
                                        <i className="fa fa-spinner fa-spin"></i> Logging In...
                                    </>
                                ):(
                                    <>
                                        Submit
                                    </>
                                )}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </form>
        </div>
    )
}

export default LoginForm;