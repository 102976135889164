import React, {useState, useEffect, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import SanitizedHTML from 'react-sanitized-html';

import {PlayerContext} from '../contexts/PlayerContext';
import {UserContext} from '../contexts/UserContext';
import SideModalSideContent from './SideModalSideContent';
import PersonCard from './PersonCard';
import TwitterLookup from './TwitterLookup';
import { toast } from 'react-toastify';

const PodcastDetail = (props) => {

    const [podcastId] = useState(props.id);
    const [loaded, setLoaded] = useState(false);
    const [details, setDetails] = useState({});
    const [addingGuest, setAddingGuest] = useState(false);
    const [addedGuest, setAddedGuest] = useState(false);
    const [voted, setVoted] = useState('none');

    const {player, clearPlayer, updatePlayerItem, addToQueue} = useContext(PlayerContext);
    const {user} = useContext(UserContext);

    const playItem = () => {
        clearPlayer();
        updatePlayerItem('id', podcastId);
        updatePlayerItem('file', details.mp3);
        updatePlayerItem('title', details.title);
        updatePlayerItem('image', details.image);
    }

    const fetchDetails = async () => {

        setLoaded(false);
        const res = await fetch('https://untethered.io/api/episodes/detail/?id='+podcastId, {credentials: 'include'});
        const data = await res.json();
        setDetails(data);
        setLoaded(true);
        //console.log(data);
        if(data.suggestedGuests.length>0){
            setVoted(data.suggestedGuests[0].voted);
        }
        
    }

    const handleSelectOption = async (person) => {

        const sendSuggestion = await fetch(`https://untethered.io/api/episodes/addGuest/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                twitter: person.screen_name,
                twitter_id: person.id,
                name: person.name,
                description: person.description,
                picture: person.profile_image_url_https,
                episode_id: podcastId,
                userToken: user.token
            })
        });
        const data = await sendSuggestion.json();
        if(data.status==='success'){
            setAddedGuest(true);
        }else{
            toast.error(data.message);
        }
    }

    useEffect(()=>{
        fetchDetails();
        // eslint-disable-next-line
    }, [podcastId]);

    const castVote = async (vote, gls_id) => {
        const sendVote = await fetch('https://untethered.io/api/episodes/guestVote/?gls_id='+gls_id+'&vote='+vote, {credentials: 'include'});
        const data = await sendVote.json();
        if(data.status==='success'){
            setVoted(vote);
        }else{
            toast.error(data.message);
        }
    }

    const castMetaVote = async(vote) => {
        const sendVote = await fetch('https://untethered.io/api/episodes/metaVote/?podcast_id='+podcastId+'&vote='+vote, {credentials: 'include'});
        const data = await sendVote.json();
        if(data.status==='success'){
            setDetails({
                ...details,
                business: data.meta.business,
                lifestyle: data.meta.lifestyle,
                travel: data.meta.travel,
                actionable: data.meta.actionable,
                entertaining: data.meta.entertaining,
                inspirational: data.meta.inspirational,
                informative: data.meta.informative
            })
        }else{
            toast.error(data.message);
        }
    }

    if(!loaded){
        return (
            <div style={{padding: 10, minHeight: 300, backgroundColor: '#FFF'}}>
                <i className="fa fa-spinner fa-spin"></i> Loading Details...
            </div>
        )
    }

    return(
        <div style={{padding: 10, backgroundColor: '#FFF'}}>
            <SideModalSideContent />
            <div className="sidemodal-main-content">
                <div className="episode-header-bar">
                <img src={details.image} alt={details.title} style={{maxWidth: 80, float: 'left', margin: '0 10px 10px 0'}} />
                    <h3><SanitizedHTML html={details.title} /></h3>
                    <div className="feed-item-published">
                        by <NavLink to={`/brand/${details.brand_id}`}>{details.brand_name}</NavLink>
                    </div>
                </div>
                
                <div>
                    <div style={{marginBottom: 10, marginLeft: 5}}>
                        {player.id===podcastId ? (
                            <div style={{fontSize: '1rem', lineHeight: '1.5'}}>
                                <i className="fa fa-volume-up"></i> Currently Playing..
                            </div>
                        ):(
                            <button className="btn btn-link" style={{padding: 0, margin: 0}} onClick={()=>playItem()}>
                                <i className="fa fa-play"></i> Play this episode
                            </button>
                        )}
                        
                    </div>
                    {player.id!==podcastId && (
                        <div style={{marginLeft: 5}}>
                            <button className="btn btn-link" style={{padding: 0, marginBottom: 5}} onClick={()=>addToQueue(podcastId, details.mp3, details.image, details.title)}>
                                <i className="fa fa-tasks"></i> Add To Queue
                            </button>
                        </div>
                    )}
                    
                    <SanitizedHTML html={details.description} />

                    <h4>Episode Guests</h4>
                    {details.guests.length>0 && (
                        <div className="guests-list">
                            {details.guests.map((guest, i)=>{
                                return <PersonCard key={i} details={guest} />
                            })}
                        </div>
                    )}
                    <div>
                        {!addingGuest ? (
                            <>
                            {details.suggestedGuests.length>0 ? (
                                <div>
                                    <h5>Suggested (Pending) Guest</h5>
                                    {details.suggestedGuests.map((person, i)=>{
                                        return (
                                            <div key={i} className="card person-card">
                                                <img src={person.picture} alt={person.name} />
                                                <div className="details">
                                                    <strong><a href={`https://twitter.com/${person.twitter}`} rel="noopener noreferrer" target="_blank">{person.name}</a></strong>
                                                    <div className="person-card-description">
                                                        {person.description}
                                                    </div>
                                                </div>
                                                {voted==='none' ? (
                                                    <div className="guest-confirmation-options">
                                                        <div style={{margin: 10}}>Is this correct? (+1 CP)</div>
                                                        <button onClick={()=>castVote('confirm', person.gls_id)} className="btn btn-sm btn-success">This Is Correct</button>
                                                        &nbsp;<button onClick={()=>castVote('reject', person.gls_id)} className="btn btn-sm btn-danger">Wrong Person</button>
                                                    </div>
                                                ):(
                                                    <>
                                                    {voted==='confirm' && (
                                                        <div className="alert alert-success">Thank you for voting to confirm this guest! If this guest is approved, you'll earn one Community Point.</div>
                                                    )}
                                                    {voted==='reject' && (
                                                        <div className="alert alert-success">Thanks for the heads up that this is the wrong guest! If this guest is reviewed and rejected, you'll earn one Community Point.</div>
                                                    )}
                                                    </>
                                                )}
                                            </div>
                                        )
                                    })}     
                                </div>
                            ):(
                                <button className="btn btn-link" onClick={()=>setAddingGuest(true)}>
                                    <i className="fa fa-plus"></i> Add Guest (+10 CP)
                                </button>
                            )}
                            
                            </>
                        ):(
                            <>
                            {addedGuest ? (
                                <>
                                    <div className="alert alert-success">Thank you for adding a Guest Suggestion! If this guest is confirmed, you'll receive 10 Community Points.</div>
                                </>
                            ):(
                                <>
                                    <button className="btn btn-link" onClick={()=>setAddingGuest(false)}>
                                        <i className="fa fa-times"></i> Cancel
                                    </button>
                                    <TwitterLookup onSelectOption={(person)=>handleSelectOption(person)} />
                                </>
                            )}
                            
                            </>
                        )}
                    </div>
                    <div style={{marginTop: 15}}>
                        <h4>Which Category Best Fits This?</h4>
                        <button style={{marginTop: 5}} className="btn btn-primary btn-sm" onClick={()=>castMetaVote('business')}>
                            <span className="badge badge-dark">{details.business}</span> Business
                        </button>&nbsp;
                        <button className="btn btn-sm" onClick={()=>castMetaVote('lifestyle')} style={{backgroundColor: '#cc62c8', color: '#FFF', marginTop: 5}}>
                            <span className="badge badge-dark">{details.lifestyle}</span> Lifestyle
                        </button>&nbsp;
                        <button style={{marginTop: 5}} className="btn btn-danger btn-sm" onClick={()=>castMetaVote('travel')}>
                            <span className="badge badge-dark">{details.travel}</span> Travel
                        </button>
                    </div>

                    <div style={{marginTop: 15}}>
                        <h4>Which Best Describes This?</h4>
                        <button style={{marginTop: 5}} className="btn btn-outline-secondary btn-sm" onClick={()=>castMetaVote('actionable')}>
                            <span className="badge badge-dark">{details.actionable}</span> Actionable
                        </button>&nbsp;
                        <button  style={{marginTop: 5}}className="btn btn-outline-secondary btn-sm" onClick={()=>castMetaVote('entertaining')}>
                            <span className="badge badge-dark">{details.entertaining}</span> Entertaining
                        </button>&nbsp;
                        <button style={{marginTop: 5}} className="btn btn-outline-secondary btn-sm" onClick={()=>castMetaVote('inspirational')}>
                            <span className="badge badge-dark">{details.inspirational}</span> Inspirational
                        </button>&nbsp;
                        <button style={{marginTop: 5}} className="btn btn-outline-secondary btn-sm" onClick={()=>castMetaVote('informative')}>
                            <span className="badge badge-dark">{details.informative}</span> Informative
                        </button>
                    </div>
                </div>
            </div>
            <div style={{clear: 'both'}}></div>
        </div>
    )
}

export default PodcastDetail;