import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import BrandEpisodesList from './BrandEpisodesList';
import SideModalSideContent from './SideModalSideContent';

const BrandDetail = (props) => {

    const [brandId] = useState(props.id);

    const [loaded, setLoaded] = useState(false);
    const [details, setDetails] = useState({});
    const [feedType, setFeedType] = useState('podcast');

    const fetchDetails = async () => {
        setLoaded(false);
        const res = await fetch('https://untethered.io/api/brands/detail/?id='+brandId);
        const data = await res.json();
        setDetails(data);
        setLoaded(true);
    }

    useEffect(()=>{
        fetchDetails();
        // eslint-disable-next-line
    }, [brandId]);

    if(!loaded){
        return (
            <div style={{padding: 10, minHeight: 300, backgroundColor: '#FFF'}}>
                <i className="fa fa-spinner fa-spin"></i> Loading Brand Details...
            </div>
        )
    }
    return(
        <div style={{padding: 10, backgroundColor: '#FFF'}}>
            <SideModalSideContent />
            <div className="sidemodal-main-content">

            
                <img src={details.brand_image} style={{width: 100, height: 100, margin: '0 10px 10px 0'}} alt={details.name} align="left" />
                <h3>{details.name}</h3>
                <div style={{margin: '10px 0'}}>
                    Hosted by <NavLink to={`/person/${details.hosts[0].id}`}>{details.hosts[0].name}</NavLink>
                    {details.hosts.length>1 && (
                        <>
                        <span> and <NavLink to={`/person/${details.hosts[1].id}`}>{details.hosts[1].name}</NavLink></span>
                        {details.hosts.length>2 && (
                            <span> and <NavLink to={`/person/${details.hosts[2].id}`}>{details.hosts[2].name}</NavLink></span>
                        )}
                        </>
                    )}
                </div>
                <div>{details.about}</div>
                
                <ul className="nav nav-tabs" style={{marginTop: 15}}>
                    <li className="nav-item">
                        <button onClick={()=>setFeedType('podcast')} className={feedType!=='podcast' ? 'nav-link' : 'nav-link active'} style={{backgroundColor: '#fff'}}>Podcast <span className="d-none d-md-inline-block"> Episodes</span></button>
                    </li>
                    <li className="nav-item">
                        <button onClick={()=>setFeedType('blog')} className={feedType!=='blog' ? 'nav-link' : 'nav-link active'} style={{backgroundColor: '#fff'}}>Blog <span className="d-none d-md-inline-block"> Posts</span></button>
                    </li>
                    <li className="nav-item">
                        <button onClick={()=>setFeedType('video')} className={feedType!=='video' ? 'nav-link' : 'nav-link active'} style={{backgroundColor: '#fff'}}>Videos</button>
                    </li>
                </ul>

                <div className="detail-feed-container" style={{margin: '10px 0'}}>
                    {feedType==='podcast' && (
                        <BrandEpisodesList brandId={brandId} />
                    )}
                </div>
                
            </div>
            <div style={{clear: 'both'}}></div>
        </div>
    )
}

export default BrandDetail;