import React from 'react';
import {NavLink} from 'react-router-dom';

const PersonCard = ({details}) => {
    return (
        <div className="card person-card">
            <img src={details.picture} alt={details.name} />
            <div className="details">
                <strong><NavLink to={`/person/${details.id}`}>{details.name}</NavLink></strong>
                <div className="person-card-description">
                    {details.description}
                </div>
            </div>
        </div>
    )
}
export default PersonCard;