import React, { useState, useEffect } from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
//import { Offline, Online } from "react-detect-offline";

import Sidebar from './components/Sidebar';
import FilterContextProvider from './contexts/FilterContext';
import PlayerContextProvider from './contexts/PlayerContext';
import UserContextProvider from './contexts/UserContext';

import Feed from './components/Feed';
import Player from './components/Player';
import SideModal from './components/SideModal';
import Header from './components/Header';

const history = createBrowserHistory();

const App = () => {

  const [showPlayer, setShowPlayer] = useState(false);
  const [showSideModal, setShowSideModal] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const thisWindowSize = useWindowSize();

  useEffect(()=>{
    setShowSideModal(window.location.pathname==='/' ? false : true);
  }, []);

  history.listen(location => {
    setShowSideModal(location.pathname==='/' ? false : true);
  });

  return (
    <Router history={history}>
    <FilterContextProvider>
      <PlayerContextProvider>
        <UserContextProvider>
          <div className="app">
            <div className="bigScreen" style={{display: showSideModal ? 'block' : 'none' }}></div>
            <Header toggleFilters={()=>setShowFilters(!showFilters)} />
            <div className={`sidebar-wrapper${showPlayer ? ' player-showing' : ''}`} style={{left: showFilters ? 0 : thisWindowSize.width>766 ? 0 : -400}}>
            <Sidebar windowSize={thisWindowSize} closeFilters={()=>setShowFilters(false)} />
            </div>
            <div className={`content-wrapper${showPlayer ? ' player-showing' : ''}`}>

                <Feed scrolledTo={window.pageYOffset} freeze={(showSideModal || (thisWindowSize.width<767 && showFilters)) ? true : false} />
               
            </div>
            <div className={`sidemodal-wrapper${showPlayer ? ' player-showing' : ''}`} style={{maxWidth: thisWindowSize.width>766 ? thisWindowSize.width-360 : '100%', right: showSideModal ? 0 : '-100%'}}>
              <Switch>
                <Route exact path='/:type/:id' render={({match})=> <SideModal type={match.params.type} id={match.params.id} />} />
              </Switch>
            </div>
            <div className="player-container" style={{bottom: showPlayer ? 0 : -70}}>
              <Player isOpen={showPlayer} openPlayer={()=>setShowPlayer(true)} closePlayer={()=>setShowPlayer(false)} />
            </div>
            <ToastContainer />
          </div>
        </UserContextProvider>
      </PlayerContextProvider>
    </FilterContextProvider>
    </Router>
  );
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});
  useEffect(() => {
    function handleResize() {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
      //console.log(windowSize);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
}

export default App;
