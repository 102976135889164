import React, { useState, useEffect, useContext } from 'react';
import {FilterContext} from '../contexts/FilterContext';

function Sidebar({windowSize, closeFilters}){

    const {filters, updateFilters} = useContext(FilterContext);
    const [localFilters, setLocalFilters] = useState(filters);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const doKeywordSearch = () => {
        updateFilters(localFilters);
    }

    useEffect(()=>{
        if(windowSize.width<767){
            closeFilters();
        }
        // eslint-disable-next-line
    }, [filters]);

    useEffect(()=>{
        if(!firstTime){
            setShowSaveButton(true);
        }else{
            setFirstTime(false);
        }
        // eslint-disable-next-line
    }, [localFilters]);

    const saveFilters = () => {
        updateFilters(localFilters);
        setShowSaveButton(false);
    }

    const updateLocalFilter = (field, value) => {
        setLocalFilters(prevState=>({...prevState, [field]: value}));
    }

    return (
        <>
            <div style={{margin: 10}}>
                <div className="btn-group special" role="group" aria-label="Content Type">
                    <button type="button" onClick={()=>updateLocalFilter('contentType', 'podcast')} className={`btn btn-secondary${localFilters.contentType==='podcast' ? ' active' : ''}`}>Podcasts</button>
                    <button type="button" onClick={()=>updateLocalFilter('contentType', 'blog')} className={`btn btn-secondary${localFilters.contentType==='blog' ? ' active' : ''}`}>Blogs</button>
                    <button type="button" onClick={()=>updateLocalFilter('contentType', 'video')} className={`btn btn-secondary${localFilters.contentType==='video' ? ' active' : ''}`}>Videos</button>
                </div>
            </div>

            <div style={{margin: 10}}>
                <div className="btn-group special" role="group" aria-label="Content Type">
                    <button type="button" onClick={()=>updateLocalFilter('subFilter', false)} className={`btn btn-secondary${localFilters.subFilter ? '': ' active'}`}>All</button>
                    <button type="button" onClick={()=>updateLocalFilter('subFilter', true)} className={`btn btn-secondary${localFilters.subFilter ? ' active': ''}`}>My Subscriptions</button>
                </div>
            </div>

            <hr />
            <div style={{margin: 10}}>
			<table className="table table-borderless table-sm vert-align-center">
                <tbody>
                <tr>
                <td align="right">Keyword</td>
                <td> 
                <div className="input-group">
                <input type="text" className="form-control" name="keyword" onChange={(e)=>updateLocalFilter('keyword', e.target.value)} placeholder="Any" id="searchTerm" value={localFilters.keyword} />
                <span className="input-group-btn">
                    <button onClick={()=>doKeywordSearch()} className="btn btn-secondary">Search</button>
                </span>
                </div>
                
                </td>
                </tr>
                
                <tr>
                <td align="right">Category</td>
                <td>
                <select className="form-control" name="category" value={localFilters.category} onChange={(e)=>updateLocalFilter('category', e.target.value)}>
                    <option value="all">All Categories</option>
                    <option value="business">Primarily Business</option>
                    <option value="travel">Primarily Travel</option>
                    <option value="lifestyle">Primarily Lifestyle</option>
                </select>
                </td>
                </tr>
                
                <tr>
                <td align="right">Descriptor</td>
                <td>
                <select className="form-control" value={localFilters.descriptor} name="descriptor" onChange={(e)=>updateLocalFilter('descriptor', e.target.value)}>
                    <option value="all">All</option>
                    <option value="actionable">Actionable</option>
                    <option value="informative">Informative</option>
                    <option value="inspirational">Inspirational</option>
                    <option value="entertaining">Entertaining</option>
                </select>
                </td>
                </tr>
                
                <tr>
                <td align="right">Order By</td>
                <td>
                <select className="form-control" value={localFilters.orderBy} onChange={(e)=>updateLocalFilter('orderBy', e.target.value)}>
                    <option value="magic_sort">Magic Sort</option>
                    <option value="published_date">Most Recent</option>
                    <option value="favorites">Most Favorites</option>
                </select>
                </td>
                </tr>
                </tbody>
		    </table>
            </div>
            {showSaveButton && (
                <div style={{margin: 10}}>
                    <button onClick={()=>saveFilters()} className="btn btn-success pulse-button btn-block">
                        Save Filters
                    </button>
                </div>
            )}
        </>
    )
}

export default Sidebar;