import React, {useContext} from 'react';
import SanitizedHTML from 'react-sanitized-html';
import {NavLink} from 'react-router-dom';

import {PlayerContext} from '../contexts/PlayerContext';

const FeedItemPodcast = ({item}) => {

    const {clearPlayer, updatePlayerItem, addToQueue} = useContext(PlayerContext);

    const playItem = () => {
        clearPlayer();
        updatePlayerItem('id', item.podcast_id);
        updatePlayerItem('file', item.podcast_file);
        updatePlayerItem('title', item.headline);
        updatePlayerItem('image', item.image);
    }

    return (
        <div className="feed-item">
            <NavLink to={`/podcast/${item.podcast_id}`}>
                <div className="feed-item-image-holder" style={{backgroundImage: `url(${item.image})`}}></div>
            </NavLink>
            <div className="feed-item-content">
                <h3 className="feed-item-title">
                    <NavLink to={`/podcast/${item.podcast_id}`} style={{color: '#000'}}>
                        <SanitizedHTML html={item.headline} />
                    </NavLink>
                </h3>
                <div className="feed-item-published">
                    <span className="d-none d-md-inline-block">Published {item.publish_date} </span> by <NavLink to={`/brand/${item.brand_id}`}>{item.brand_name}</NavLink>
                </div>
                <div className="feed-item-actions" style={{marginTop: 10}}>
                    <button className="btn btn-link" onClick={()=>playItem()}>
                        <i className="fa fa-play"></i> Play
                    </button>

                    <button className="btn btn-link" onClick={()=>addToQueue(item.podcast_id, item.podcast_file, item.image, item.headline)} style={{marginLeft: 15}}>
                        <i className="fa fa-tasks"></i> Add To Queue
                    </button>
                </div>
                <div className="feed-item-summary">
                    <NavLink to={`/podcast/${item.podcast_id}`} style={{color: '#000'}}>
                        <SanitizedHTML 
                        allowedTags={ [ 'b', 'i', 'strong', 'br', 'p']} 
                        selfClosing={ [ 'br']}
                        html={item.description} />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default FeedItemPodcast;