import React, { useState, useEffect, useContext } from 'react';

import {FilterContext} from '../contexts/FilterContext';
import FeedItemPodcast from './FeedItemPodcast';

const Feed = (props) => {
    const {filters} = useContext(FilterContext);
    const [loaded, setLoaded] = useState(false);
    const [results, setResults] = useState([]);
    const [resultSet, setResultSet] = useState(1);
    const [scrollReturn, setScrollReturn] = useState(0);
    const [paging, setPaging] = useState({
        thisPage: 1,
        pageCount: 1,
        resultCount: 1
    })

    const fetchPosts = async (unload) => {
        
        if(unload){
            setLoaded(false);
            setResults([]);
            setResultSet(1);
        }
        let startPoint = unload ? 0 : (resultSet-1)*18;
        let newResults = unload ? [] : results;
        const res = await fetch('https://untethered.io/api/episodes/list/?cat='+filters.category+'&descriptor='+filters.descriptor+'&orderby='+filters.orderBy+'&search='+encodeURI(filters.keyword)+'&start='+startPoint);
        const data = await res.json();
        
        setPaging({
            thisPage: data.this_page,
            pageCount: data.page_count,
            resultCount: data.results_count
        })

        for(let i=0; i<data.results.length; i++){
            newResults.push(data.results[i]);
        }
        setResults(newResults);
        setResultSet(unload ? 2 : resultSet+1);
        setLoaded(true);
        //console.log(data);
    }

    useEffect(()=>{
        fetchPosts(true);
        // eslint-disable-next-line
    }, [filters]);

    useEffect(()=>{
        //console.log('scrolled to '+props.scrolledTo);
        if(props.freeze){
            setScrollReturn(props.scrolledTo);
        }else{
            setTimeout(()=>{
                window.scrollTo(0, scrollReturn);
            }, 200);
        }
        // eslint-disable-next-line
    }, [props])

    if(!loaded){
        return (
            <div>Checking...</div>
        )
    }
    let formatter = Intl.NumberFormat();
    return (
        <div style={{position: props.freeze ? 'fixed' : 'relative'}}>
            <div className="container">
                {results.map((item, i)=>{
                    return (
                        <div key={i}>
                            <FeedItemPodcast item={item} />
                        </div>
                    )
                })}
                <div style={{textAlign: 'center', padding: '10px 0 15px'}}>
                    {results.length>0 ? (
                        <>
                            Loaded {formatter.format(results.length)} of {formatter.format(paging.resultCount)} results<br />
                            {paging.pageCount>paging.thisPage && (
                                <button className="btn btn-outline-secondary" onClick={()=>fetchPosts(false)} style={{marginTop: 5}}>Load More Results</button>
                                
                            )}
                        </>
                    ):(
                        <>No Results</>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Feed;