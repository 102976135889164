import React, {useContext} from 'react';
import {UserContext} from '../contexts/UserContext';

import Modal from '../components/shared/modal/Modal';
import useModal from '../components/shared/modal/useModal';
import LoginForm from './shared/LoginForm';

const AccountMenu = () => {

    const {user} = useContext(UserContext);
    const {isShowing, toggle} = useModal();
    //console.log(user);

    return (
        <>
            {user.id>0 ? (
                <>
                'Logged In' 
                </>
            ):(
                <>
                    <button className="btn btn-sm btn-success" onClick={toggle}>Log In</button>
                    <Modal isShowing={isShowing} hide={toggle} title='Please log in to continue'>
                        <div>
                            <LoginForm />
                        </div>
                    </Modal>
                </>
            )}
        </>
    )
}
export default AccountMenu;