import React from 'react';

const SideModalSideContent = () => {

    return (
        <div className="sidemodal-side-content">
            {window.innerWidth>1099 && (
                <img src="https://via.placeholder.com/150x600" alt="placeholder" />
            )}
        </div>
    )
}

export default SideModalSideContent;