import React, {useState} from 'react';

const TwitterLookup = (props) => {
    const [query, setQuery] = useState('');
    const [resultsLoaded, setResultsLoaded] = useState(false);
    const [results, setResults] = useState([]);
    const [searchStarted, setSearchStarted] = useState(false);

    const fetchOptions = async () => {
        setResultsLoaded(false);
        setSearchStarted(true);
        const res = await fetch('https://untethered.io/api/twitter/searchPeople/?q='+encodeURI(query));
        const data = await res.json();
        setResults(data.options);
        setResultsLoaded(true);
        //console.log(data);
    }

    const doGuestSearch = () => {
        fetchOptions();
    }

    //props should have onSelectOption

    return (
        <div>
            
            <div className="input-group">
            <input type="text" className="form-control" value={query} placeholder="Guest Name" onChange={(e)=>setQuery(e.target.value)}  />
                <span className="input-group-btn">
                    <button onClick={()=>doGuestSearch()} className="btn btn-secondary">Search</button>
                </span>
            </div>
            
            {searchStarted && (
                <div className="twitter-search-results" style={{margin: '10px 0'}}>
                    {!resultsLoaded ? (
                        <div>
                            <i className="fa fa-spinner fa-spin"></i> Checking Twitter...
                        </div>
                    ):(
                        <>
                            {results.length>0 && (
                                <div style={{margin: '10px 0'}}>
                                    Click the picture of the person who should be added as the guest. Make sure it's the right one!
                                </div>
                            )}
                            {results.map((person, i)=>{
                                return (
                                    <div key={i} className="card" style={{marginBottom: 5, padding: 6, minHeight: 80}}>
                                        <div style={{position: 'absolute', top: 10, left: 6}}>
                                            <img src={person.profile_image_url_https} alt={person.name} onClick={()=>props.onSelectOption(person)} />
                                        </div>
                                        <div style={{paddingLeft: 56 }}>
                                            <div>{person.name}</div>
                                            <div>
                                                {person.description}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            )}
            
        </div>
    )
}
export default TwitterLookup;